// eslint-disable-next-line no-unused-vars
import { oldData, newData, isSatisfiedBuyMultipleFromCouponData } from './getEstimatedInfoMock'
import { getAllCatIdsFromParentsTree } from 'public/src/pages/goods_detail_v2/utils/productDetail.js'

const handleEstimatedCoupons = (coupons, isPaidUser = false) => {
  let couponCodes = []
  return (coupons || []).filter(item => {
    if (couponCodes.includes(item.coupon_code)) return false
    let isReserve = isPaidUser ? item.is_vip_promotion : !item.is_vip_promotion
    isReserve && couponCodes.push(item.coupon_code)
    return isReserve
  })
}

const getPriceInfoPmsc = (info, promotionInfo, flashType, newFlashPromotion, EstimatedShowType) => {
  if (!info) return null
  let optimalCouponList = info.coupon_prices?.filter?.(item => !item.is_vip_promotion) || []
  const optimalLength = optimalCouponList.length
  let isStacked = optimalLength > 1
  optimalCouponList.sort((a, b) => a.calculate_order - b.calculate_order)
  let optimalCoupon = optimalCouponList[optimalLength - 1] || null // 选calculate_order最大的一张最优券作为折扣率的展示
  let value = optimalCoupon?.after_coupon_price || null
  let { retailPrice, salePrice } = info || {}
  let coupons = handleEstimatedCoupons(info?.coupons)
  optimalCouponList = optimalCouponList?.map(optimal => {
    return {
      ...(optimal || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimal?.coupon_code) || {}),
      rule: optimal?.rule,
      discountValue: optimal?.coupon_discount_value
    }
  })
  let bestPromotion = info.promotion_discount_info?.[0]?.id ? promotionInfo.find(item => item.id == info.promotion_discount_info?.[0]?.id) : (promotionInfo[0] || {})
  // 付费会员 29 或闪购 10 活动存在，但未命中
  let promotionTypeId = bestPromotion?.typeId || null
  const promotionLogoType = bestPromotion?.promotionLogoType || null
  if (promotionTypeId == 29 || (promotionTypeId == 10 && [2, 3].includes(Number(flashType)))) promotionTypeId = null
  // 新型闪购无到手价，但是有pmsc到手价
  if ([2, 3].includes(flashType) && newFlashPromotion ) {
    value = null
  }
  let isSatisfied = optimalCoupon?.satisfied || 0
  let discountPercent = null
  if (EstimatedShowType === 'New' && optimalCoupon?.coupon_discount && isSatisfied) {
    discountPercent = optimalCoupon?.coupon_discount + '%'
  }
  return {
    value,
    isSatisfied,
    thresholdValue: optimalCoupon?.rule?.min || null,
    needPrice: optimalCoupon?.need_price || null,
    optimalCoupon,
    discountPercent,
    retailPrice,
    salePrice,
    coupons,
    promotionInfo: {
      id: bestPromotion?.id || null,
      promotionLogoType,
      typeId: promotionTypeId,
      flashType: flashType
    },
    discountValue: optimalCoupon?.coupon_discount_value,
    discountValueForBelt: optimalCoupon?.coupon_discount_price,
    discountPercentForBelt: !!value && isSatisfied && optimalCoupon?.coupon_discount ? optimalCoupon.coupon_discount + '%' : null,
    isStacked,
    // flashDiscountValue: info.discount_value || info.discountPrice,
    optimalCouponList
  }
}

const getSheinClubEstimatedPrice = (normalAfterCouponsInfo, sheinClubAfterCouponsInfo, isPaidUser, sheinClubPromotionInfo, extendsObj, config = {}) => {
  if (isPaidUser) {
    return sheinClubAfterCouponsInfo
  } else {
    const { Estimatedclubshow } = config
    let vipPrice = sheinClubPromotionInfo?.price?.amount || 0 // 付费会员价
    let normalEstimatedPrice = parseFloat(normalAfterCouponsInfo?.after_coupon_price?.amount || 0) // 原价到手价
    let vipEstimatedPrice = parseFloat(sheinClubAfterCouponsInfo?.after_coupon_price?.amount || 0) // 付费会员到手价

    // 同时存在原价到手价和付费会员到手价
    if (normalEstimatedPrice && vipEstimatedPrice) {
      if (normalEstimatedPrice > vipEstimatedPrice) {
        return normalAfterCouponsInfo
      }
      if (Estimatedclubshow == 'New' && normalEstimatedPrice < vipEstimatedPrice) {
        extendsObj.isNewSheinClubShipping = true
        return normalAfterCouponsInfo
      }
      return null
    }
    // 只存在原价到手价
    if (normalEstimatedPrice && vipPrice && !vipEstimatedPrice) {
      if (normalEstimatedPrice > vipPrice) {
        return normalAfterCouponsInfo
      }
      if (Estimatedclubshow == 'New' && normalEstimatedPrice < vipPrice) {
        extendsObj.isNewSheinClubShipping = true
        return normalAfterCouponsInfo  
      }
      return null
    }
    return null
  }
}
const getSheinClubPriceInfoPmsc = (info, promotionInfo, isPaidUser, sheinClubPromotionInfo, config = {}) => {
  if (!info) return null
  let optimalCoupons = info.coupon_prices || []
  const { Estimatedclubshow, EstimatedShowType } = config
  const normalCouponList = optimalCoupons?.filter?.(item => !item.is_vip_promotion) || []
  normalCouponList.sort((a, b) => a.calculate_order - b.calculate_order)
  let normalAfterCouponsInfo = normalCouponList[normalCouponList.length - 1]

  const sheinClubCouponList = optimalCoupons?.filter?.(item => !!item.is_vip_promotion) || []
  sheinClubCouponList.sort((a, b) => a.calculate_order - b.calculate_order)
  let sheinClubAfterCouponsInfo = sheinClubCouponList[sheinClubCouponList.length - 1]

  let betterItem = null
  let paidUserValue = sheinClubAfterCouponsInfo?.after_coupon_price || null
  let extendsObj = { isNewSheinClubShipping: false }

  if ((paidUserValue && isPaidUser) || normalAfterCouponsInfo?.after_coupon_price) {
    betterItem = getSheinClubEstimatedPrice(normalAfterCouponsInfo, sheinClubAfterCouponsInfo, isPaidUser, sheinClubPromotionInfo, extendsObj, { Estimatedclubshow })
  }
  let optimalCouponList = betterItem ? (!!betterItem?.is_vip_promotion ? sheinClubCouponList : normalCouponList) : []
  let isStacked = optimalCouponList.length > 1
  let optimalCoupon = optimalCouponList[optimalCouponList.length - 1] || null // 选后一张到手价作为折扣率的展示

  let { retailPrice, salePrice } = info || {}
  let value = optimalCoupon?.after_coupon_price || null
  let thresholdValue = optimalCoupon?.rule?.min || null
  let isSatisfied = optimalCoupon?.satisfied || 0
  let coupons = handleEstimatedCoupons(info?.coupons, isPaidUser)
  optimalCouponList = optimalCouponList?.map(optimal => {
    return {
      ...(optimal || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimal?.coupon_code) || {}),
      rule: optimal?.rule,
      discountValue: optimal?.coupon_discount_value
    }
  })
  let bestPromotion = info.promotion_discount_info?.[0]?.id ? promotionInfo?.find?.(item => item.id == info.promotion_discount_info?.[0]?.id) : (promotionInfo[0] || {})
  const promotionLogoType = bestPromotion?.promotionLogoType || null

  let discountPercent = null
  if (
    EstimatedShowType == 'New' &&
    isSatisfied &&
    sheinClubAfterCouponsInfo?.coupon_discount &&
    value
  ) {
    discountPercent = optimalCoupon?.coupon_discount + '%'
  }
  return {
    paidUserDiscountValue: info?.vipDiscountPrice || null,
    isPaidUser,
    value,
    isSatisfied,
    thresholdValue,
    needPrice: optimalCoupon?.need_price || null, // 满足门槛还差多少钱
    optimalCoupon: optimalCoupon,
    discountPercent,
    retailPrice,
    salePrice: isPaidUser ? sheinClubPromotionInfo?.price : salePrice,
    coupons,
    promotionInfo: {
      id: isPaidUser ? info.promotionInfo?.[0]?.id : null,
      typeId: isPaidUser ? 29 : null,
      promotionLogoType,
      flashType: null
    },
    discountValue: optimalCoupon?.coupon_discount_value,
    discountValueForBelt: isPaidUser ? sheinClubAfterCouponsInfo?.coupon_discount_price : normalAfterCouponsInfo?.coupon_discount_price,
    discountPercentForBelt: !!value && isSatisfied ? (isPaidUser ? sheinClubAfterCouponsInfo?.coupon_discount + '%' : normalAfterCouponsInfo?.coupon_discount + '%') : null,     
    isStacked,
    optimalCouponList,
    isNewSheinClubShipping: extendsObj.isNewSheinClubShipping,
    sheinClubAfterCouponsInfo, // 付费会员到手价
  }
}
const getEstimatedPriceInfoPfes = (info, detail, flashType, EstimatedShowType) => {
  let optimalCouponList = info?.productCoupon?.optimal_coupons || info?.coupon_prices || []
  const optimalLength = optimalCouponList.length
  let isStacked = optimalLength > 1
  optimalCouponList.sort((a, b) => a.calculate_order - b.calculate_order)
  let optimalCoupon = optimalCouponList[optimalLength - 1] || null // 选后一张到手价作为折扣率的展示
  let value = optimalCoupon?.after_coupon_price || null
  let isSatisfied = optimalCoupon?.satisfied || 0
  let thresholdValue = optimalCoupon?.rule?.min || null
  let needPrice = optimalCoupon.need_price || null
  let coupons = handleEstimatedCoupons(info?.productCoupon?.coupons[0]?.coupons || info?.coupons)
  optimalCouponList = optimalCouponList?.map(optimal => {
    return {
      ...(optimal || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimal?.coupon_code) || {}),
      rule: optimal?.rule,
      discountValue: optimal?.discount_value
    }
  })
  let discountPercent = null
  if (EstimatedShowType === 'New' && optimalCoupon.discount_percent && isSatisfied) {
    discountPercent = parseInt(optimalCoupon?.discount_percent * 100) + '%'
  }
  return {
    value, // 到手价
    isSatisfied, // 是否满足门槛
    thresholdValue, // 门槛价
    needPrice, // 距离门槛还需要多少钱
    optimalCoupon, // 最优券
    discountPercent,
    retailPrice: detail?.retailPrice, // 原价 - 无活动、无券
    salePrice: info?.price, // 活动价
    coupons,
    promotionInfo: {
      id: info.id,
      typeId: info.typeId,
      flashType: flashType,
    },
    discountValue: optimalCoupon?.discount_value,
    discountValueForBelt: optimalCoupon?.total_discount_value,
    discountPercentForBelt: !!value && isSatisfied && optimalCoupon?.discount_percent ? parseInt(optimalCoupon?.discount_percent * 100) + '%' : null,
    isStacked,
    // flashDiscountValue: info.discount_value,
    optimalCouponList
  }
}

const amountSymbolFormat = (couponList) => {
  // 前端数据格式兼容处理。 本次后端返回的字段格式和之前不统一。
  couponList.forEach(item => {
    item.after_coupon_price = {
      ...item.after_coupon_price,
      amountWithSymbol: item.after_coupon_price?.amount_text,
      usdAmount: item.after_coupon_price?.usd_amount
    }
    item.coupon_discount_value = {
      ...item.coupon_discount_value,
      amountWithSymbol: item.coupon_discount_value?.amount_text,
      usdAmount: item.coupon_discount_value?.usd_amount
    }
    item.need_price = {
      ...item.need_price,
      amountWithSymbol: item.need_price?.amount_text,
      usdAmount: item.need_price?.usd_amount
    }
    
    if(!item.discountValue) item.discountValue = item.coupon_discount_value
    if(item.rule?.min) {
      item.rule.min = {
        ...item.rule.min,
        amountWithSymbol: item.rule.min.amount_text,
        usdAmount: item.rule.min.usd_amount
      }
    }
    if(item.rule?.max) {
      item.rule.max = {
        ...item.rule.max,
        amountWithSymbol: item.rule.max.amount_text,
        usdAmount: item.rule.max.usd_amount
      }
    }
    if(item.rule?.value_amount) {
      item.rule.value_amount = {
        ...item.rule.value_amount,
        amountWithSymbol: item.rule.value_amount.amount_text,
        usdAmount: item.rule.value_amount.usd_amount
      }
    }
  })
}

// s3会员到手价 - typeId = 12 
const getExclusivePricePspc = (pspcSkcMallPrices, pmscInfo, mallCode, EstimatedShowType) => {
  let info = pspcSkcMallPrices || {}
  let optimalCouponList = info?.coupon_price_list?.filter(item => item.is_vip_promotion == 1) || []
  const optimalLength = optimalCouponList.length
  amountSymbolFormat(optimalCouponList)
  let isStacked = optimalLength > 1
  optimalCouponList = optimalCouponList.slice().sort((a, b) => a.calculate_order - b.calculate_order)
  let optimalCoupon = optimalCouponList[optimalLength - 1] || null // 选后一张到手价作为折扣率的展示
  let value = optimalCoupon?.after_coupon_price || null
  let isSatisfied = optimalCoupon?.satisfied || 0
  let thresholdValue = optimalCoupon?.rule?.min || null
  let coupons = handleEstimatedCoupons(pmscInfo?.coupons)
  optimalCouponList = optimalCouponList?.map(optimal => {
    return {
      ...(optimal || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimal?.coupon_code) || {}),
      rule: optimal?.rule,
      discountValue: optimal?.discount_value || optimal?.discountValue
    }
  })
  let discountPercent = null
  if (EstimatedShowType === 'New' && optimalCoupon?.coupon_discount && isSatisfied) {
    discountPercent = parseInt(optimalCoupon?.coupon_discount) + '%'
  }
  let needPrice = optimalCoupon?.need_price
  return {
    value, // 到手价
    isSatisfied, // 是否满足门槛
    thresholdValue, // 门槛价
    needPrice, // 满足门槛还差多少钱
    optimalCoupon, // 最优券
    discountPercent,
    discountPercentForBelt: !!value && isSatisfied && optimalCoupon?.discount_percent ? parseInt(optimalCoupon?.discount_percent * 100) + '%' : null,
    retailPrice: pmscInfo?.retailPrice, // 原价 - 无活动、无券
    salePrice: pmscInfo?.price, // 活动价
    coupons,
    promotionInfo: {
      id: pmscInfo.promotionInfo?.find?.(item => item.typeId == 12),
      typeId: 12,
    },
    discountValue: optimalCoupon?.coupon_discount_value,
    discountValueForBelt: optimalCoupon?.coupon_discount,
    isStacked,
    optimalCouponList,
    isS3: true
  }
}

// ab 活动包装到手价
const getEstimatedInfoAbPrice = (mallInfo, promotionInfo, abExtraParams) => {
  const { pspcPriceInfo, sheinClubPromotionInfo, skuCode, mallCode } = abExtraParams
  const hasClubPromotionInfo = !!sheinClubPromotionInfo?.price?.amount
  let value = hasClubPromotionInfo ? sheinClubPromotionInfo.price : mallInfo.salePrice
  let discountValue = mallInfo?.discountPrice || {}, unit_discount = mallInfo.unit_discount
  if(hasClubPromotionInfo) {
    let real_ab_price_discount = {}
    unit_discount = mallInfo.discountValue
    if(skuCode) { // 获取sku的ab折扣
      const skuMallPrices = pspcPriceInfo?.skuMallPrices || []
      const skuItem = skuMallPrices.find(item => item.product_code == skuCode)
      const skuMallPriceItem = skuItem?.mall_prices?.find(item => item.mall_code == mallCode) || {}
      real_ab_price_discount = skuMallPriceItem?.real_ab_price_discount || {}
    }else { // 获取sku的ab折扣
      const skcMallPrices = pspcPriceInfo?.skcMallPrices || []
      const skcMallItem = skcMallPrices.find(item => item.mall_code == mallCode)
      real_ab_price_discount = skcMallItem?.real_ab_price_discount || {}
    }
    if(real_ab_price_discount.amount) {
      discountValue = {
        ...real_ab_price_discount,
        amountSimpleText: real_ab_price_discount.amount_simple_text,
        amountWithSymbol: real_ab_price_discount.amount_text,
        usdAmount: real_ab_price_discount.usd_amount,
        usdAmountWithSymbol: real_ab_price_discount.usd_amount_text,
      }
    }
  }
  let { id, typeId, endTimestamp } = promotionInfo?.[0] || {}
  let coupons = [{
    apply_for: 101,
    coupon_discount: unit_discount,
    filter_rule: 0,
    bind_status: 1,
    rule: [
      {
        value_amount: {
          ...discountValue
        }
      }
    ],
    start_time: -1,
    end_time: endTimestamp,
    satisfied: 1,
    discountValue: discountValue,
  }]
  let discountPercent = unit_discount + '%'
  return {
    value, // 到手价
    isSatisfied: 1, // 是否满足门槛
    isPaidUser: hasClubPromotionInfo,
    optimalCoupon: coupons[0], // 最优券
    discountPercent,
    retailPrice: mallInfo?.retailPrice, // 原价 - 无活动、无券
    salePrice: mallInfo?.salePrice, // 活动价
    coupons,
    promotionInfo: {
      id,
      typeId,
      flashType: null,
    },
    discountValue: discountValue,
    discountValueForBelt: discountValue,
    discountPercentForBelt: discountPercent,
    isAb: true,
    optimalCouponList: coupons
  }
}


const checkPromStock = (promotionInfo, n) => {
  let temp = promotionInfo || []
  for (let i of temp) {
    let singNum = i.singNum || Infinity
    let member = i.member || Infinity
    let orderNum = i.orderNum || Infinity
    if (singNum < n || member < n || orderNum < n) return false 
  }
  return true
}

const checkSiteCat = (SiteUID, parentCats, N_ESTIMATED_SITE_CAT) => {
  const nEstimatedApolloConf = N_ESTIMATED_SITE_CAT || {}
  if (!nEstimatedApolloConf) return false
  const confCats = nEstimatedApolloConf[SiteUID]
  if (!confCats) return false
  const curCats = getAllCatIdsFromParentsTree(parentCats)
  for (let i of curCats) {
    if (confCats.includes(Number(i))) return true
  }
  return false
}

/**
 * @function 活动N件到手价
*/
const getBuyMultipleEstimatedPriceFromPromotion = (info, promotionInfo, sheinClubPromotionInfo, isPaidUser, isSheinClub, SiteUID, parentCats, N_ESTIMATED_SITE_CAT) => {
  let promType9Info = promotionInfo?.find?.(item => item.typeId == 9)
  if (!promType9Info) return null
  let buyMultiplePic = promType9Info.buyLimit

  // 库存/活动限购判断
  let stock = info.stock
  let buyLimit = promType9Info.buyLimit
  if (stock < buyLimit) return null
  if (!checkPromStock(promotionInfo, buyMultiplePic)) return null

  // 站点/品类判断
  if (!checkSiteCat(SiteUID, parentCats, N_ESTIMATED_SITE_CAT)) return null

  let mulSaleInfo = (info?.mul_price_info?.find?.(item => item.id == promType9Info.id) || {}).mul_sale_price || []
  let curMulInfo = isPaidUser && isSheinClub ? mulSaleInfo?.find?.(item => item?.is_vip_price) : mulSaleInfo?.find?.(item => !item?.is_vip_price)
  let value = curMulInfo?.single_sale_price
  if (!value) return null
  if (value.amount == sheinClubPromotionInfo?.price?.amount) return null
  return {
    value: value, // N件单件到手价
    buyMultiplePic,
    mulSaleInfo: curMulInfo,
    promType9Info,
    isPaidUser: isSheinClub && isPaidUser,
    coupons: handleEstimatedCoupons(info?.coupons, isPaidUser && isSheinClub),
    isSatisfiedBuyMultiple: 1,
    isSatisfiedBuyMultipleFromPromotion: 1,
  }
}

/**
 * @function 券N件到手价
*/
const getBuyMultipleEstimatedPriceFromCoupon = (info, promotionInfo, isSheinClub, isPaidUser, SiteUID, parentCats, newFlashPromotion, isS3, pspcSkcMallPrices, N_ESTIMATED_SITE_CAT) => {
  // 库存/活动限购判断
  let stock = info.stock
  if (stock < 2) return null
  if (!checkPromStock(promotionInfo, 2)) return null
  // 站点/品类判断
  if (!checkSiteCat(SiteUID, parentCats, N_ESTIMATED_SITE_CAT)) return null
  let doubleOptimalCoupons = isS3 ? (pspcSkcMallPrices?.double_optimal_coupons || []) : (info.double_optimal_coupons || [])
  if (isS3) amountSymbolFormat(doubleOptimalCoupons)
  let doubleCurOptimalCoupons = doubleOptimalCoupons.filter(item => !item.is_vip_promotion)
  if ((isSheinClub && isPaidUser) || newFlashPromotion || isS3) {
    doubleCurOptimalCoupons = doubleOptimalCoupons.filter(item => !!item.is_vip_promotion)
  }
  doubleCurOptimalCoupons.sort((a, b) => a.calculate_order - b.calculate_order)
  let optimalCoupon = doubleCurOptimalCoupons[doubleCurOptimalCoupons.length - 1] || null
  if (!optimalCoupon) return null
  let value = optimalCoupon.single_after_coupon_price
  let doubleValue = optimalCoupon.after_coupon_price
  let discountPercent = optimalCoupon?.coupon_discount_value
  let coupons = info.coupons
  let optimalCouponList = doubleCurOptimalCoupons?.map(optimal => {
    return {
      ...(optimal || {}),
      ...(coupons?.find?.(item => item.coupon_code === optimal?.coupon_code) || {}),
      rule: optimal?.rule,
      discountValue: optimal?.coupon_discount_value
    }
  })
  value.amountWithSymbol = value.amountWithSymbol || value.amount_text
  doubleValue.amountWithSymbol = doubleValue.amountWithSymbol || doubleValue.amount_text
  return {
    value, // N件单件到手价
    doubleValue,
    optimalCoupon,
    optimalCouponList,
    coupons: handleEstimatedCoupons(info?.coupons, isPaidUser && isSheinClub),
    discountValueForBelt: discountPercent,
    buyMultiplePic: 2,
    isSatisfiedBuyMultiple: 1,
    isSatisfiedBuyMultipleFromCoupon: 1,
    isPaidUser: isSheinClub && isPaidUser,
    promotionInfo: {
      id: promotionInfo?.[0]?.id,
      typeId: promotionInfo?.[0]?.typeId,
      flashType: promotionInfo?.[0]?.flashType,
    },
    isS3
  }
}

/**
 * @function 获取到手价组件参数
*/
const getCompProps = (estimatedInfo, promotionInfo, sheinappwordsize, s3ClubPriceInfo) => {
  if (!estimatedInfo) return null
  if (!estimatedInfo?.value?.amountWithSymbol) return null
  let { E_Price_Cal, E_Time_Limit } = estimatedInfo?.EstimatedPrice?.p || {}
  let { EstimatedShowType } = estimatedInfo || {}
  let onlySatisfied = E_Price_Cal === 'Have_Threshold'
  let { isSatisfied } = estimatedInfo
  if (
    onlySatisfied &&
    !isSatisfied &&
    !(estimatedInfo?.isEstimatedstrategyNone || estimatedInfo?.isAbCoexist) &&
    !estimatedInfo?.isSatisfiedBuyMultiple
  ) return null
  let { isPaidUser, value } = estimatedInfo

  /* 倒计时相关计算 start */
  const bestId = estimatedInfo?.promotionInfo?.id
  const bestActivity = promotionInfo?.find(item=>item.id == bestId)
  // let couponList = estimatedInfo?.coupons
  // let bestCoupon = couponList?.find(item=>{
  //   return item.coupon_code == estimatedInfo?.optimalCoupon?.coupon_code
  // })
  const tenDaysLater = new Date().getTime() / 1000 + 864000
  let optimalCouponList = estimatedInfo?.optimalCouponList || []
  const couponEndList = optimalCouponList?.map(coupon => {
    return coupon?.end_time
  })
  const couponEnd = Math.min.apply(null, couponEndList) || tenDaysLater
  const activityEnd = bestActivity?.endTimestamp || tenDaysLater
  const bestEndTime = couponEnd < activityEnd ? couponEnd : activityEnd

  const nowStamp = new Date().getTime()
  const endTimestamp = bestEndTime * 1000
  const isEndTimeIn24 = endTimestamp - nowStamp > 0 && endTimestamp - nowStamp  < 60 * 60 * 24 * 1000
    
  const { typeId, flashType } = estimatedInfo?.promotionInfo || {}
  const isShowCountdown = ((E_Time_Limit === 'Show' && isSatisfied) || E_Time_Limit === 'Nothrodshow')  && value?.amountWithSymbol && !(typeId == 10 && flashType == 1) && isEndTimeIn24
  /* 倒计时相关计算 end */

  const commonConfig = {
    isShowCountdown,
    bestEndTime,
    EstimatedShowType,
    flashType: estimatedInfo.promotionInfo?.flashType,
    sheinappwordsize: sheinappwordsize,
    isS3: !!s3ClubPriceInfo
  }

  let EstimatedNothreShowType = estimatedInfo.EstimatedNothreShowType

  if (commonConfig.isS3) {
    return value ? {
      config: {
        ...commonConfig,
        styleType: 'NORMAL',
        EstimatedNothreShowType
      },
      price: {
        ...estimatedInfo.value,
        discountPercent: estimatedInfo?.discountPercent,
        addOnAmountWithSymbol: isSatisfied ? '' : estimatedInfo.thresholdValue?.amountWithSymbol,
        needPrice: estimatedInfo.needPrice,
        buyMultiplePic: estimatedInfo.buyMultiplePic
      }
    } : null
  }
  if (isPaidUser) {
    return value ? {
      config: {
        ...commonConfig,
        styleType: 'NORMAL',
        isPaidUser: estimatedInfo?.isPaidUser,
        EstimatedNothreShowType
      },
      price: {
        ...estimatedInfo.value,
        discountPercent: estimatedInfo?.discountPercent,
        addOnAmountWithSymbol: isSatisfied ? '' : estimatedInfo.thresholdValue?.amountWithSymbol,
        needPrice: estimatedInfo.needPrice,
        buyMultiplePic: estimatedInfo.buyMultiplePic
      }
    } : null
  }
  if (
    [2, 3].includes(estimatedInfo?.promotionInfo?.flashType) &&
    estimatedInfo.promotionInfo?.id &&
    estimatedInfo.promotionInfo?.typeId &&
    estimatedInfo.value
  ) {
    return {
      config: {
        ...commonConfig,
        styleType: 'SPECIAL_FLASH',
        EstimatedNothreShowType
      },
      price: {
        amountWithSymbol: estimatedInfo?.value?.amountWithSymbol,
        addOnAmountWithSymbol: isSatisfied ? '' : estimatedInfo?.thresholdValue?.amountWithSymbol,
        usdAmount: estimatedInfo.value?.usdAmount || '',
        discountPercent: estimatedInfo?.discountPercent,
        needPrice: estimatedInfo.needPrice,
        buyMultiplePic: estimatedInfo.buyMultiplePic
      }
    }
  }
  if (value.amountWithSymbol) {
    return {
      config: {
        ...commonConfig,
        styleType: 'NORMAL',
        EstimatedNothreShowType
      },
      price: {
        amountWithSymbol: value.amountWithSymbol,
        addOnAmountWithSymbol: isSatisfied ? '' : estimatedInfo.thresholdValue?.amountWithSymbol,
        usdAmount: value?.usdAmount || '',
        discountPercent: estimatedInfo.discountPercent,
        needPrice: estimatedInfo.needPrice,
        buyMultiplePic: estimatedInfo.buyMultiplePic
      }
    }
  }
  return null
}

/**
 * @function getEstimatedInfo 获取到手价相关信息
 * @param {'Have_Threshold' | 'Non_Threshold' | 'None'} abtValue 
 * @param {string} mallCode 
 * @param {object} detail 
 * @param {object} skuInfo 
 * @param {object} isPaidUser 
 * @param {object} showSheinClub 
 * @param {object} flashGoods 
 * @param {object} flashType 
 * @param {object} exclusivePromotionPrice s3会员
 * @param {object} s3ClubPriceInfo pspc接口-s3会员 
 * @param {object} newFlashPromotion pfesInfo
*/
export default ({
  abtValue: abtValue = {},
  mallCode: mallCode = '',
  detail: detail = {},
  skuInfo: skuInfo = {},
  isPaidUser: isPaidUser = false,
  showSheinClub: showSheinClub = false,
  flashType: flashType = 0,
  exclusivePromotionPrice: exclusivePromotionPrice = {},
  s3ClubPriceInfo: s3ClubPriceInfo = {},
  newFlashPromotion: newFlashPromotion = null,
  promotionInfo: promotionInfo = [],
  sheinClubPromotionInfo: sheinClubPromotionInfo,
  isSoldOut: isSoldOut,
  estimatedPriceConfig,
  SiteUID,
  showEstimatedPrice: showEstimatedPrice = true,
  isOnlyAbPrice: isOnlyAbPrice = false,
  showNoSatisfied: showNoSatisfied = false,
  closeEstimatedAndAbPrice: closeEstimatedAndAbPrice = false,
  complianceMode: complianceMode = true,
  complianceModeDe: complianceModeDe = true,
  pspcPriceInfo: pspcPriceInfo = {},
  parentCats: parentCats = {},
  N_ESTIMATED_SITE_CAT: N_ESTIMATED_SITE_CAT = {}
}) => {
  /** 打开注释直接展示到手价，根据你的需求微调 ./getEstimatedInfoMock.js 用完记得关！ */ 
  // return null
  // return oldData
  // return newData
  // return isSatisfiedBuyMultipleFromCouponData
  let { EstimatedPrice, EstimatedShowType, EstimatedNothreShowType, Estimatedstrategy, Estimatedclubshow, sheinappwordsize, middleast } = abtValue || {}
  let { E_Price_Cal } = EstimatedPrice?.p || {}
  let mallInfo = skuInfo?.mall?.[mallCode] || detail?.mall?.[mallCode]
  const returnAssignFn = (res) => {
    if (!res) res = {}
    let coupons = res.coupons || handleEstimatedCoupons(mallInfo?.coupons, sheinClubPromotionInfo && isPaidUser)
    let result = Object.assign(res, {
      EstimatedPrice,
      EstimatedShowType,
      EstimatedNothreShowType,
      complianceMode,
      complianceModeDe,
      sheinappwordsize,
      coupons,
      isMiddleastNew: middleast === 'new'
    })
    let estimatedCompProps = getCompProps(result, promotionInfo, sheinappwordsize, s3ClubPriceInfo)
    return {
      ...result,
      estimatedCompProps
    }
  }

  if(closeEstimatedAndAbPrice) return returnAssignFn(null)


  if (exclusivePromotionPrice?.amountWithSymbol && !s3ClubPriceInfo) return returnAssignFn({ coupons: mallInfo?.coupons || [] })
  if (isSoldOut) return returnAssignFn({ coupons: mallInfo?.coupons || [] })
  

  // ab 价
  let abResult = null
  if (
    detail?.mall?.[mallCode]?.promotionInfo?.find?.(item => item.typeId == 32)
  ) {
    let abExtraParams = {}
    if(isPaidUser) {
      abExtraParams = {
        pspcPriceInfo,
        sheinClubPromotionInfo,
        skuCode: skuInfo?.sku_code || '',
        mallCode
      }
    }
    abResult = getEstimatedInfoAbPrice(mallInfo, detail?.mall?.[mallCode].promotionInfo, abExtraParams)
  }
  // 仅返回ab价
  if (isOnlyAbPrice && abResult) return returnAssignFn(abResult)
  if (!showEstimatedPrice) return returnAssignFn(null)
  let isSpecialFlash = [2, 3, 4].includes(flashType) && newFlashPromotion

  let isSheinClub = (isPaidUser || (!isPaidUser && showSheinClub)) && sheinClubPromotionInfo

  const getResult = () => {
    if (!E_Price_Cal || E_Price_Cal === 'None') return null
    // s3会员到手价
    if(s3ClubPriceInfo) {
      let priceInfo = getExclusivePricePspc(s3ClubPriceInfo, mallInfo, mallCode, EstimatedShowType)
      return priceInfo
    }
    // 新型闪购 用pfes到手价
    if (isSpecialFlash) {
      return getEstimatedPriceInfoPfes(newFlashPromotion, mallInfo, flashType, EstimatedShowType)
    }
    // 付费会员 || 付费会员未开通
    if (isSheinClub) {
      let priceInfo = getSheinClubPriceInfoPmsc(mallInfo, promotionInfo, isPaidUser, sheinClubPromotionInfo, { EstimatedShowType, Estimatedclubshow })
      return priceInfo
    }
  
    return getPriceInfoPmsc(mallInfo, promotionInfo, flashType, newFlashPromotion, EstimatedShowType)
  }

  let result = getResult()

  if(EstimatedPrice?.p?.DetailEstimatedPrice === 'unshow' && result?.isSatisfied){ // 当DetailEstimatedPrice=unshow，则不生效满足门槛的到手价。 不影响ab到手价逻辑。
    result = null
  }
  if (!abResult && (!result || !result.value || !result.isSatisfied) && !complianceMode && !complianceModeDe) {
    let buyMultipleEstimatedPriceFromPromotionInfo = getBuyMultipleEstimatedPriceFromPromotion(mallInfo, promotionInfo, sheinClubPromotionInfo, isPaidUser, isSheinClub, SiteUID, parentCats, N_ESTIMATED_SITE_CAT)
    if (buyMultipleEstimatedPriceFromPromotionInfo) return returnAssignFn(buyMultipleEstimatedPriceFromPromotionInfo)
  }
  if (!result && !abResult) return returnAssignFn(null)

  if (!result?.isSatisfied && !abResult) {
    // N 件券到手价
    let buyMultipleEstimatedPriceInfo = getBuyMultipleEstimatedPriceFromCoupon(mallInfo, promotionInfo, isSheinClub, isPaidUser, SiteUID, parentCats, newFlashPromotion, !!s3ClubPriceInfo, s3ClubPriceInfo, N_ESTIMATED_SITE_CAT)
    if (buyMultipleEstimatedPriceInfo) return returnAssignFn(buyMultipleEstimatedPriceInfo)
  }

  // 快速加车不展示未满足门槛到手价
  if (!showNoSatisfied && !result?.isSatisfied && !abResult) {
    return returnAssignFn(null)
  }

  if (E_Price_Cal === 'Non_Threshold' && !result?.isSatisfied) {
    // 未满足门槛凑单展示策略逻辑
    const configItem = estimatedPriceConfig?.find(item => item?.SiteUID?.includes(SiteUID)) || {}
    const { strategyList = [] } = configItem
    const  strategyItem = strategyList.find(item => item.name === Estimatedstrategy) || {}
    const { medianCustomerPrice, multiple } = strategyItem
    // const { coupon_discount_value, thresholdValue, needPrice } = result // 减免金额 门槛价 凑单金额 接口没提供美元价
    // const discountUsdPrice = +(coupon_discount_value?.usdAmount || 0) // 减免金额美元价 接口没提供美元价
    // const needUsdPrice = +(needPrice?.usdAmount || 0) // 美元凑单金额 接口没提供美元价
    const { value, salePrice, thresholdValue } = result // 券后价 售卖价 门槛价
    const saleUsdPrice = +(salePrice?.usdAmount || 0) // 售卖价
    const afterUsdPrice = +(value?.usdAmount || 0) // 券后价
    const discountUsdPrice = saleUsdPrice - afterUsdPrice // 售卖价-券后价 = 减免金额
    const thresholdUsdValue = +(thresholdValue?.usdAmount || 0) // 美元券门槛价
    const needUsdPrice = thresholdUsdValue - saleUsdPrice
    if (!showNoSatisfied) {
      Object.assign(result, {
        value: null,
        isEstimatedstrategyNone: true,
        complianceMode
      })
    } else if (Estimatedstrategy === 'None' || !Estimatedstrategy) { // 未配置策略或none，隐藏
      Object.assign(result, {
        value: null,
        isEstimatedstrategyNone: true,
        complianceMode
      })
    }else if(medianCustomerPrice && !multiple) { // 只配置中位数，需满足策略一
      if(thresholdUsdValue > (+medianCustomerPrice)) {
        Object.assign(result, {
          value: null,
          isEstimatedstrategyNone: true,
          complianceMode
        })
      }
    } else if(multiple && !medianCustomerPrice) {  // 只配置倍数，需满足策略二
      if(needUsdPrice > (+multiple * discountUsdPrice)) {
        Object.assign(result, {
          value: null,
          isEstimatedstrategyNone: true,
          complianceMode
        })
      }
    }else if(medianCustomerPrice && multiple) { // 都配置，如果都不满足，则隐藏
      if(thresholdUsdValue > (+medianCustomerPrice) && needUsdPrice > (+multiple * discountUsdPrice)) {
        Object.assign(result, {
          value: null,
          isEstimatedstrategyNone: true,
          complianceMode
        })
      }
    }
  }

  // 只存在 ab 价
  if (abResult && !result) {
    return returnAssignFn(abResult)
  }

  // 存在 ab 价和未满足门槛未满足策略
  if (abResult && result && !result?.isSatisfied && !result?.value) {

    abResult.optimalCouponList = [ ...abResult.coupons, ...result.optimalCouponList]
    abResult.coupons = [ ...abResult.coupons, ...result.coupons]
    abResult.isEstimatedstrategyNone = true
    return returnAssignFn(abResult)
  }

  // ab 价和到手价共存
  if (abResult && result && result?.value) {

    result.optimalCouponList = [ ...abResult.coupons, ...result.optimalCouponList]
    result.coupons = [ ...abResult.coupons, ...result.coupons]

    if (result.isSatisfied) {
      result.discountPercent = result.discountPercent ||
        (result.optimalCoupon?.coupon_discount || result.optimalCoupon?.discount_percent * 100) + '%'
    } else {
      result.discountPercent = abResult.discountPercent
      result.value = abResult.value
    }

    result.isAbCoexist = true
  }

  return returnAssignFn(result)
}
