import { transformImg } from '@shein/common-function'

export default {
  methods: {
    /**
     * 对商品主图进行质量压缩
     * @param {*} img 图片地址
     * @param {*} type 主图类型 1: 900x / 2: 405x552 / 3: 220x293
     * @returns
     */
    dprInsert(img = '', type = '1', mode) {
      let IS_RETINA = false
      if (typeof window !== 'undefined') {
        IS_RETINA =
          typeof window.devicePixelRatio !== 'undefined' &&
          window.devicePixelRatio > 1
      }
      let USE_SUFFIX
      if (mode == 'quickView') {
        USE_SUFFIX = IS_RETINA ? '_thumbnail_810x' : '_thumbnail_405x'
        if (type === 'mini') {
          // USE_SUFFIX = IS_RETINA ? '_thumbnail_160x' : '_thumbnail_80x'
          USE_SUFFIX = ''
        }
      } else {
        USE_SUFFIX = IS_RETINA ? '_thumbnail_900x' : '_thumbnail_600x'
        if (type == '2') {
          USE_SUFFIX = IS_RETINA ? '_thumbnail_810x' : '_thumbnail_405x552'
        }
        if (type == '3') {
          USE_SUFFIX = '_thumbnail_220x293'
        }
        if (type == '4') {
          // USE_SUFFIX = IS_RETINA ? '_thumbnail_160x' : '_thumbnail_80x'
          USE_SUFFIX = ''
        }
        if (type == 'Hi-Q1') {
          // 类型1的高质量
          USE_SUFFIX = '_thumbnail_900x' // 默认900 否则原图 商详主图
        }
        if(type == 'reuse'){
          USE_SUFFIX = '_thumbnail_336x' // 复用列表商品项加载过的图片
        }
      }
      const akamaiSuffix =
        img.includes('//img.ltwebstatic.com/images2_pi/') ||
        transformImg({ img }).includes('//img.shein.com/')
          ? IS_RETINA
            ? '1199'
            : '799'
          : ''
      const index =
        img.indexOf('.jpg') !== -1 ? img.indexOf('.jpg') : img.indexOf('.png')
      if (index !== -1 && USE_SUFFIX) {
        return (
          img.slice(0, index) + (USE_SUFFIX + akamaiSuffix) + img.slice(index)
          // img.slice(0, index) + (akamaiSuffix) + img.slice(index)
        )
      } else {
        return img
      }
    },
  },
}
