<template>
  <div
    v-expose="{ id: '1-8-6-179', data: analysis }"
    class="best-deal-tips__container"
  >
    <Icon
      name="sui_icon_lowest_price_12px_2"
      size="12px"
      color="#C44A01"
    />
    <span class="text">{{ text }}</span>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'
export default {
  name: 'BestDealTips',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    tipsType: {
      type: [String, Number],
      default: ''
    },
    analysis: {
      type: Object,
      default: (() => {})
    }
  }
}
</script>

<style lang="less">
.best-deal-tips {
  &__container {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    color: @sui_color_discount_dark;
    .text {
      padding-left: 4px;
    }
  }
}
</style>
