<template>
  <div
    class="productPrice-estimatedNoThresholdNew"
    @click="handleClick"
  >
    <div class="productPrice-estimatedNoThresholdNew__left">
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9996 1.30353L13.7232 7.68433L2.49963 10.8036L0.999604 4.30349L11.9996 1.30353Z"
          fill="url(#paint0_linear_92_20260)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.01233 9.2883L1.01233 10.7388V13.7883H14.5123V4.28833H1.01233V7.83782L2.01233 9.2883Z"
          fill="url(#paint1_linear_92_20260)"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.2124 11.622L8.927 6.28833H10.5459L6.83134 11.622H5.2124ZM6.10162 8.06635C6.59254 8.06635 6.99051 7.66838 6.99051 7.17746C6.99051 6.68654 6.59254 6.28857 6.10162 6.28857C5.6107 6.28857 5.21273 6.68654 5.21273 7.17746C5.21273 7.66838 5.6107 8.06635 6.10162 8.06635ZM9.65722 11.6218C10.1481 11.6218 10.5461 11.2239 10.5461 10.733C10.5461 10.242 10.1481 9.84406 9.65722 9.84406C9.1663 9.84406 8.76833 10.242 8.76833 10.733C8.76833 11.2239 9.1663 11.6218 9.65722 11.6218Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear_92_20260"
            x1="2.66665"
            y1="8.88881"
            x2="2.10174"
            y2="4.2301"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF8A69" />
            <stop
              offset="1"
              stop-color="#FA6338"
            />
          </linearGradient>
          <linearGradient
            id="paint1_linear_92_20260"
            x1="5.77101"
            y1="4.42806"
            x2="7.93018"
            y2="10.1076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FF8A69" />
            <stop
              offset="1"
              stop-color="#FA6338"
            />
          </linearGradient>
        </defs>
      </svg>
      <p
        :class="{
          'productPrice-estimatedNoThresholdNew__left-text': true,
          'productPrice-estimatedNoThresholdNew__left-text_ar': cssRight
        }"
        v-html="thresholdText"
      >
      </p>
    </div>
    <div
      v-if="isShowAdd"
      class="productPrice-estimatedNoThresholdNew__right"
    >
      <p class="productPrice-estimatedNoThresholdNew__right-text">
        {{ language.SHEIN_KEY_PC_28097 || 'Add' }}
      </p>
      <Icon
        name="sui_icon_more_right_18px"
        size="12px"
      />
    </div>
  </div>
</template>

<script name="EstimatedNoThresholdNew" setup>
import { Icon } from '@shein-aidc/icon-vue3'
import { template } from '@shein/common-function'
import { computed } from 'vue'

const props = defineProps({
  price: {
    type: Object,
    default: () => ({
      // 到手价
      amountWithSymbol: '',
      // 门槛价
      addOnAmountWithSymbol: ''
    })
  },
  estimatedInfo: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  config: {
    type: Object,
    default: () => ({
      styleType: 'NORMAL',
      isPaidUser: false,
    })
  },
  countTimer: {
    type: Object,
    default: () => ({})
  },
  cssRight: {
    type: Boolean,
    default: false
  },
  isShowAdd: {
    type: Boolean,
    default: false
  }
})
const thresholdText = computed(() => {
  let isEstimatedNothreShowTypeNew1 = props.estimatedInfo?.EstimatedNothreShowType == 'New1'
  let new1Lan = props.language.SHEIN_KEY_PC_28095 || 'this item only {0} if add-ons {1} more'
  let new2Lan = props.language.SHEIN_KEY_PC_28096 || 'add-ons {1} more to enjoy  {2} off'
  if (props.cssRight) {
    new1Lan = new1Lan.replace(/\s/g, '&nbsp;')
    new2Lan = new2Lan.replace(/\s/g, '&nbsp;')
  }
  const strWithSpan = (str) => `<span>${str}</span>`
  let resultStr = ''
  resultStr = isEstimatedNothreShowTypeNew1 ? 
    template(strWithSpan(props.price.amountWithSymbol), strWithSpan(props.price.needPrice.amountWithSymbol), new1Lan) :
    template('', strWithSpan(props.price.needPrice.amountWithSymbol), strWithSpan(props.price.amountWithSymbol), new2Lan)
  if (props.countTimer?.timeObj && props.config?.isShowCountdown) {
    const { H, M, S } = props.countTimer?.timeObj || {}
    resultStr += (',' + strWithSpan(`&nbsp;${H}:${M}:${S}`))
  }
  return resultStr
})

const emit = defineEmits(['click-link'])
const handleClick = () => {
  emit('click-link')
}

</script>

<style lang="less">
.productPrice-estimatedNoThresholdNew {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: linear-gradient(269.95deg, #FFF6F3 0.04%, #FFE6DD 99.96%);
  padding: 6px 8px;
  &__left {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    & > svg {
      display: block;
      min-width: 16px;
      margin-right: 4px;
    }
  }
  &__left-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #000;

    & > span {
      color: #FA6338;
      font-size: 12px;
      font-weight: 700;
    }
  }
  &__left-text_ar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  &__right {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  &__right-text {
    margin-left: 12px;
    margin-right: 2px;
    font-size: 12px;
    font-weight: 700;
  }
}
</style>
