export function parsePercentText(text) {
  if (!text?.replaceAll || typeof window === 'undefined') {
    return text
  }
  const { GB_cssRight } = window?.gbCommonInfo || {}
  if (!GB_cssRight) {
    return text
  }
  text = text.trim()
  const len = text.length
  return text?.replaceAll(/(\d+)%|%(\d+)/g, (...params) => {
    const [patternStr, group, group1, startIndex] = params
    if (startIndex === 0 || startIndex === len - patternStr.length && group) {
      return `%${group}`
    }
    if (group1) {
      return `${group1}%`
    }
    return patternStr
  })
} 
  
