<template>
  <div
    class="price-estimated-percent"
    :style="{
      background: (isSpecialFlash || isQuickAddBelt) ? 'transparent' : '#fff',
    }"
  >
    <div class="price-estimated-percent__container">
      <Icon
        v-if="config.flashType == 1 && !isBeltType"
        name="sui_icon_flashsale_16px" 
        size="18px" 
        color="#FACF19" 
      />
      <span
        class="price-estimated-percent__price"
        :style="{color: strategyStyle.mainPriceColor}"
        v-html="mainPriceWordSizeFormater(estimatedPrice)"
      ></span>
      <img 
        v-if="config.isS3"
        class="price-estimated-percent__s3-tag"
        src="https://img.ltwebstatic.com/images3_ccc/2024/09/06/2d/17256037293379c1b812e0bbcbed140c7dd8a0d490.png"
      />
      <span 
        v-if="isShowVat"
        ref="vatDom"
        v-expose="{ id: '1-8-6-186' }"
        :class="{ 'break-word': isVatBreak}"
        class="inclusive-tip"
      >
        {{ taxIncludedText || language.SHEIN_KEY_PC_25026 }}
      </span>
      <div 
        ref="percentDom"
        class="price-estimated-percent__percent-wrap"
        :class="{ 'break-word': isPercentBreak}"
        :style="{
          cursor: hasLink? 'pointer' : 'default'
        }"
        @click="handleClick"
      >
        <div 
          class="price-estimated-percent__estimated"
          :style="{
            'border-radius':!isPercentBreak ? '2px 0 0 0' : '2px 0 0 2px',
            color: strategyStyle.mainColor,
            background: strategyStyle.mainBackground
          }" 
        >
          {{ isAb && isMiddleastNew ? (language.SHEIN_KEY_PC_32260 || 'with special coupon') : language.SHEIN_KEY_PC_27318 }}
          <div
            :class="[isPercentBreak ? 'price-estimated-percent__top-arrow' : 'price-estimated-percent__left-arrow']"
          ></div>
        </div>
        <div 
          class="price-estimated-percent__percent"
          :style="{
            background: strategyStyle.percentBackground,
            color: strategyStyle.percentColor,
            'font-size': strategyStyle.percentFontWeight
          }"
        >
          {{ parsePercentText(`-${price.discountPercent}`) }}
        </div>
      </div>
      <div
        ref="retailDom"
        class="price-estimated-percent__retail"
        :class="{ 'break-word': isRetailBreak}"
        :style="{
          cursor: hasLink? 'pointer' : 'default'
        }"
        @click="handleClick"
      >
        <span class="price-num">{{ estimatedInfo.retailPrice.amountWithSymbol }}</span>
        <ClientOnly>
          <Icon
            v-if="hasLink"
            name="sui_icon_more_right_12px_1" 
            size="12px"
            :is-rotate="isCssRight" 
          />
        </ClientOnly>
      </div>
    </div>
    <div 
      v-if="(countTimer.timeObj && config.isShowCountdown)"
      class="price-estimated-percent__countdown-wrap"
    >
      <span>{{ language.SHEIN_KEY_PC_26828 }}</span>
      <div
        class="price-estimated-percent__countdown-time"
        v-html="countdownTips"
      ></div>
    </div>
  </div>
</template>

<script name="EstimatedTagNew" setup>
import { ClientOnly } from '@sheinfe/vue-client-only'
import { template } from '@shein/common-function'
import { computed, ref } from 'vue'
import salePriceWordSize from 'public/src/pages/goods_detail_v2/utils/salePriceWordSize'
import { Icon } from '@shein-aidc/icon-vue3'
import { parsePercentText } from 'public/src/pages/goods_detail_v2/utils/ar.js'

const isCssRight = ref(!!gbCommonInfo?.GB_cssRight)
const percentDom = ref(null)
const retailDom = ref(null)
const vatDom = ref(null)

const isPercentBreak = ref(false)
const isRetailBreak = ref(false)
const isVatBreak = ref(false)


const ESTIMATED_TAG_NEW1_STYLE = {
  normal: {
    mainPriceColor: '#FA6338',

    mainColor: '#FA6338',
    mainBackground: '#FFECE9',

    percentColor: '#FA6338',
    percentBackground: '#FFD9CE',
    percentFontWeight: '700'
  },
  beltType: {
    mainPriceColor: '#000',

    mainColor: '#FA6338',
    mainBackground: '#FFECE9',

    percentColor: '#FFF',
    percentBackground: '#FA6338',
    percentFontWeight: '700'
  },
  beltTypeSpecialFlash: {
    mainPriceColor: '#FA6338',

    mainColor: '#FA6338',
    mainBackground: '#FFECE9',

    percentColor: '#FFF',
    percentBackground: '#FA6338',
    percentFontWeight: '400'
  },
  beltTypeBrand: {

    mainPriceColor: '#FA6338',

    mainColor: '#FA6338',
    mainBackground: '#FFECE9',

    percentColor: '#FFF',
    percentBackground: '#FA6338',
    percentFontWeight: '400'
  },
  paidVip: {
    mainPriceColor: '#873C00',

    mainColor: '#873C00',
    mainBackground: '#FFECE9',

    percentColor: '#873C00',
    percentBackground: '#FFD9CE',
    percentFontWeight: '700'
  },
  quickAddBelt: {
    mainPriceColor: '#FA6338',
    mainColor: '#FF3D00',
    mainBackground: '#FFF',
    percentColor: '#FFF',
    percentBackground: '#F54811',
    percentFontWeight: '700'
  },
  S3Vip: { // 增加 s3 类型颜色
    mainPriceColor: '#806208',
    mainColor: '#806208',
    mainBackground: 'rgba(224, 203, 158, 0.40)',
    percentColor: '#806208',
    percentBackground: '#EDDEC0'
  },
}

const props = defineProps({
  config: {
    type: Object,
    default: () => ({
      styleType: 'NORMAL',
      isPaidUser: false,
      sheinappwordsize: ''
    })
  },
  price: {
    type: Object,
    default: () => ({
      // 到手价
      amountWithSymbol: '',
      // 门槛价
      addOnAmountWithSymbol: ''
    })
  },
  countTimer: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  countdownTips: {
    type: String,
    default: ''
  },
  estimatedInfo: {
    type: Object,
    default: () => ({})
  },
  isSpecialFlash: {
    type: Boolean,
    default: false
  },
  isShowFrom: {
    type: Boolean,
    default: false
  },
  isShowVat: {
    type: Boolean,
    default: false
  },
  isBeltType: {
    type: Boolean,
    default: false
  },
  texts: {
    type: Object,
    default: () => ({})
  },
  containerStyle: {
    type: Object,
    default: () => ({})
  },
  taxIncludedText: {
    type: String,
    default: ''
  },
  isBeltTypeBrand: {
    type: Boolean,
    default: false
  },
  isQuickAddBelt: {
    type: Boolean,
    default: false
  },
  hasLink: {
    type: Boolean,
    default: false
  },
  sheinappwordsize: {
    type: Boolean,
    default: false
  },
  isMiddleastNew: {
    type: Boolean,
    default: false
  },
  isAb: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['click-link'])

const estimatedPrice = computed(() => {
  let fromText = props.isShowFrom ? template('', props.language.SHEIN_KEY_PC_17875) : ''
  let spanFrom = props.sheinappwordsize !== 'newprice' ? `<span style="font-size: 16px">${fromText}</span>` : fromText
  return `${spanFrom}${props.price.amountWithSymbol}`
})

const strategyStyle = computed(() => {
  let { config, isBeltType, isSpecialFlash, isQuickAddBelt, isBeltTypeBrand } = props
  let { isS3 } = config
  let { normal, beltType, beltTypeSpecialFlash, paidVip, quickAddBelt, beltTypeBrand, S3Vip } = ESTIMATED_TAG_NEW1_STYLE
  if (isS3) return S3Vip
  if (isQuickAddBelt) return quickAddBelt
  if (isBeltTypeBrand) return beltTypeBrand
  if (config.isPaidUser) return paidVip
  if (isBeltType) {
    return isSpecialFlash ? beltTypeSpecialFlash : beltType
  }
  return normal
})

// 价格大小实验
const mainPriceWordSizeFormater = (priceStr) => {
  return salePriceWordSize({
    priceStr,
    gate: props.sheinappwordsize == 'newprice'
  })
}

const handleClick = () => {
  emit('click-link')
}

</script>

<style lang="less">
.price-estimated {
  &__estimated-wrap{
    display: flex;
    padding: 0 6px;
    border-radius: 100px;
    height: 34px;
    align-items: center;
    margin-right: 8px;
  }
  &__percent{
    color: #FA6338;
    border:1px solid #FA6338;
    padding: 1px 2px;
    border-radius: 2px;
    margin-right: 4px;
  }
}
.price-estimated-percent {
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  &__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    word-break: break-all;
    position: relative;
    .inclusive-tip {
      font-size: 12px;
      line-height: 14px;
      vertical-align: middle;
      color: @sui_color_gray_dark2;
      margin-right: 4px;
      align-self: flex-end;
      &.break-word{
        margin-top: 10px;
      }
    }
  }
  &__price{
    font-size: 24px;
    font-weight: 900;
    line-height: 1;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  &__s3-tag{
    width: 27px;
    margin-right: 4px;
  }
  &__percent-wrap{
    display:flex;
    align-items: center;
    padding-right: 8px;
    &.break-word{
      margin-top: 10px;
    }
  }
  &__estimated {
    display: flex;
    align-items: center;
    padding: 2px 3px;
    position: relative;
    background-color: #FFECE9;
  }
  &__percent {
    display: flex;
    align-items: center;
    padding: 2px 3px;
    border-radius: 0 2px 2px 0;
    font-weight: bold;
  }
  &__left-arrow {
    background-color: #FFECE9;
    position: absolute;
    left: -5px;
    bottom: 0;
    width: 5px;
    height: 5px;
    clip-path: path('M0.216889622,4.99538049 C2.87769329,4.88181811 5,2.6887547 5,0 L5,5 L0,5 Z');
  }
  &__top-arrow {
    background-color: #FFECE9;
    position: absolute;
    width: 8px;
    height: 5px;
    top: -5px;
    left: calc(50% - 4px);
    clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  }
  &__retail{
    color: #666666;
    font-size: 12px;
    display: flex;
    align-items: center;
    .price-num{
      text-decoration: line-through;
    }
    &.break-word{
      margin-top: 10px;
    }
  }
  &__countdown-wrap {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-left: 12px;
    height: 24px;
    color: #FA6338;
  }
  &__countdown-time{
    display: flex;
    align-items: center;
    margin-left: 2px;
    span {
      width: 18px;
      height: 18px;
      line-height: 18px;
      color: #FA6338;
      background-color: #FFECE9;
      text-align: center;
      margin: 0 2px;
      border-radius: 2px;
    }
  }
}
</style>
