/**
 * @file sheinappwordsize 大小价实验
*/

const coverStringWithSpan = (str, size) => {
  return `<span style="font-size: ${size}px">${str}</span>`
}
/**
 * @function salePriceWordSize
*/
export default ({
  priceStr: priceStr = '',
  size: size = 28,
  smallSize: smallSize = 18,
  gate: gate = true
}) => {
  if (!gate) return priceStr
  if (!priceStr) return ''
  // 同时有 , 和 . 
  let hasCommaAndPeriod = priceStr.includes(',') && priceStr.includes('.')
  let price = priceStr.replace(/[^0-9.,]/ig, '')
  let integer = ''
  if (hasCommaAndPeriod) {
    integer = price.split('.')[0]
  } else {
    integer = parseInt(price)
  }
  let tempMark = '##'
  let replaceStr = priceStr.replace(String(integer), tempMark)
  let coverSmallSize = coverStringWithSpan(replaceStr, smallSize)
  let coverBigSize = coverStringWithSpan(integer, size)

  return coverSmallSize.replace(tempMark, coverBigSize)
}
