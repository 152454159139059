<template>
  <s-dialog
    v-model:visible="visible"
    class="OCP-dialog"
    :modal="false"
    :append-to-body="true"
    :type="'W480'"
    @close="close"
  >
    <div
      class="inner-box"
      @click="onDialogClick"
    >
      <div class="level-1">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.9158 11.9579C23.9158 18.5587 18.5587 23.9158 11.9579 23.9158C5.35714 23.9158 0 18.5587 0 11.9579C0 5.35714 5.35714 0 11.9579 0C18.5587 0 23.9158 5.35714 23.9158 11.9579ZM18.2358 6.32573C17.7934 5.93112 17.2911 6.43335 17.2911 6.43335L9.80548 14.0027L6.80405 11.372C6.33769 10.9056 5.95504 11.1209 5.77567 11.2524C5.27344 11.659 5.68001 12.3286 5.68001 12.3286L9.05214 17.339C9.76961 18.2478 10.3675 17.3509 10.3675 17.3509L18.176 7.29432C18.5228 6.78013 18.3673 6.44531 18.2358 6.32573Z"
            fill="#5EBD66"
          />
        </svg>
        <i></i>
        <span>{{ language.SHEIN_KEY_PC_25012 }}</span>
      </div>

      <div class="level-2">
        <!-- 相关订单列表 -->
        <ul class="image-box">
          <li
            v-for="(item, index) in displayOrderList"
            :key="index"
          >
            <!-- 主图 -->
            <img
              class="main-image"
              :src="item.image"
            />
            <!-- 剩余订单数量蒙版 -->
            <div
              v-if="item.overOrders"
              class="sum-order-cover"
            >
              +{{ item.overOrders }}
            </div>

            <!-- 数量蒙版 -->
            <template v-else>
              <div
                v-if="item.sum > 1"
                class="sum-cover"
              >
                X{{ item.sum }}
              </div>
            </template>
          </li>
        </ul>
        <div class="view-button">
          {{ language.SHEIN_KEY_PC_21189 }}
        </div>
      </div>
    </div>
  </s-dialog>
</template>

<script setup>
import { toRefs, defineEmits, ref, computed, watch } from 'vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const LIST_MAX_SHOW_ORDER = 8

const emit = defineEmits(['close', 'oneClickPayDone'])
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  relativeOrders: {
    type: Array,
    default: () => ([])
  },
  currentOrderNum: {
    type: [Number, String],
    default: '',
  },
  language: {
    type: Object,
    default: () => ({})
  },
})
const { visible, relativeOrders, language, currentOrderNum } = toRefs(props)
const closeTimer = ref(-1)

// 展示的相关订单列表
const displayOrderList = computed(() => {
  const source = relativeOrders.value
  if (Array.isArray(source) && source.length) {
    // 主订单一般为第一项
    let mainOrder = null
    const restOrders = []
    for (let i = 0; i < source.length; i++) {
      const { goods_list = [], billno } = source[i] || {}
      // 命中主订单
      if (billno === currentOrderNum.value) {
        mainOrder = { goods_list, billno }
      } else {
        if (!Array.isArray(goods_list)) {
          continue
        }
        const sum = goods_list.reduce((pre, cur) => {
          const { order_goods_sum = 0 } = cur || {}
          const curNum = Number(order_goods_sum) || 0
          return pre + curNum
        }, 0)
        restOrders.unshift({
          image: goods_list[0]?.goods_thumb || '',
          sum,
          billno,
        })
      }
    }
    // 若未命中订单号，则默认第一项为主订单
    if (!mainOrder) {
      mainOrder = source[0]
      if (!mainOrder) {
        return restOrders
      }
      restOrders.pop()
    }

    // 主订单拆解
    const mainOrderProducts = []
    mainOrder.goods_list?.forEach(({ order_goods_sum = 1, goods_thumb = '' } = {}) => {
      mainOrderProducts.push({
        image: goods_thumb,
        sum: Number(order_goods_sum) || 1,
        billno: mainOrder.billno
      })
    })

    // 构造渲染订单
    const renderList = [
      ...restOrders,
      ...mainOrderProducts,
    ]

    const total = renderList.length
    // 8个以内，原样展示，超过8个只显示8个
    if (total <= LIST_MAX_SHOW_ORDER) {
      return renderList
    }

    // 超过8个，筛选前7项正常展示，最后一项收起
    const overflowCount = Math.max(total - LIST_MAX_SHOW_ORDER + 1, 1)
    const edgeOrder = renderList[LIST_MAX_SHOW_ORDER - 1]
    if (!edgeOrder) {
      return renderList.slice(0, LIST_MAX_SHOW_ORDER - 1)
    }
    edgeOrder.overOrders = overflowCount
    return [
      ...renderList.slice(0, LIST_MAX_SHOW_ORDER - 2),
      edgeOrder,
    ]
  }
  return []
})

// 订单对话框点击，跳转订单页
const onDialogClick = () => {
  // 埋点
  daEventCenter.triggerNotice({
    daId: '1-8-6-135',
    extraData: {
      scene: 'one_tap_pay',
      style: 'snakebar',
      order_no: currentOrderNum.value,
      merge_count: relativeOrders.value?.length || 1,
      type: 'orders',
    },
  })
  setTimeout(() => {
    window.location.href = '/user/orders/list?from=PageGoodsDetail'
  }, 3e2)
}

const close = () => emit('close')

// 计时关闭对话框
watch([visible], ([isVisible]) => {
  clearTimeout(closeTimer.value)
  if (isVisible) {
    closeTimer.value = setTimeout(() => {
      close()
    }, 5e3)
    // 埋点
    daEventCenter.triggerNotice({
      daId: '1-8-6-134',
      extraData: {
        scene: 'one_tap_pay',
        style: 'snakebar',
        order_no: currentOrderNum.value,
        merge_count: relativeOrders.value?.length || 1,
      },
    })
  }
})

</script>
<style lang="less">
  .OCP-dialog {
    .sui-dialog__wrapper {
      top: 180px;
      width: 540px;
      padding: 0;
      border: none;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
      cursor: pointer;

      .inner-box {
        padding: 18px 32px;
      }
    }

    .level-1, .level-2 {
      display: flex;
      align-items: center;
    }

    .level-1 {
      i {
        display: inline-block;
        width: 12px;
        height: 1px;
      }
      span {
        display: inline-block;
        font-size: 16px;
        color: @sui_color_main;
      }
    }

    .level-2 {
      height: 36px;
      margin-top: 12px;
      justify-content: space-between;

      .view-button {
        min-width: 84px;
        height: 28px;
        box-sizing: border-box;
        border: 1px solid @sui_color_gray_dark1;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        line-height: 14px;
        color: @sui_color_gray_dark1;
        padding: 7px 10px;
      }
    }

    // 相关订单列表
    .image-box {
      height: 100%;
      display: flex;
      align-items: center;

      li {
        height: 100%;
        width: 36px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
        position: relative;
      }
      .main-image {
        display: block;
        width: 36px;
      }

      .sum-cover, .sum-order-cover {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 36px;
        background-color: rgba(34, 34, 34, 0.6);
        font-weight: 700;
        color: #fff;
        text-align: center;
      }
      .sum-cover {
        height: 16px;
        font-size: 10px;
        line-height: 16px;
      }

      .sum-order-cover {
        height: 36px;
        font-size: 14px;
        line-height: 36px;
      }
    }
  }
</style>
