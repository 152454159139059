<template>
  <div class="recommendRetailPrice">
    <p class="recommendRetailPrice__value">
      {{ recommendRetailPrice.value }}
    </p>
    <div
      v-if="recommendRetailPrice.discountPercent"
      class="recommendRetailPrice__discountPercent"
    >
      {{ recommendRetailPrice.discountPercent }}
    </div>
  </div>
</template>

<script setup name="RecommendRetailPrice">
import { watch } from 'vue'
import { debounce } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'

const props = defineProps({
  recommendRetailPrice: {
    type: Object,
    default: () => ({})
  },
  isFloorType: {
    type: Boolean,
    default: false
  },
  isQuickAdd: {
    type: Boolean,
    default: false
  }
})

const debounceExpose = 
debounce({
  func: function () {
    daEventCenter.triggerNotice({
      daId: '1-8-6-256', 
      extraData: {
        recommend_retail_price: props.recommendRetailPrice.usdAmountWithSymbol,
        location: props.isQuickAdd ? 'popup' : 'page'
      }
    })
  },
  wait: 600,
})

watch(
  () => props.recommendRetailPrice?.skuCode,
  val => {
    if (!val) return
    if (props.isFloorType) return
    debounceExpose()
  },
  {
    immediate: true
  }
)

</script>

<style lang="less">
.recommendRetailPrice {

  display: flex;
  align-items: center;

  height: 20px;

  white-space: nowrap;
  font-family: SF Pro;
  font-size: 12px;
  font-weight: 400;
  color: #959595;
  text-align: left;

  &__discountPercent {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0 3px;
    height: 16px;
    border: 1px solid #959595;
    margin-left: 4px;
    border-radius: 4px;
  }
}
</style>
